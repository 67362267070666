export const LISTING_TYPE_HUMAN_NAME_MAP_TITLE = {
  JOB: getText('Job'),
  INTERNSHIP: getText('Internship'),
  VOLOP: getText('Volunteer Opportunity'),
  ORG: getText('Organization'),
  USER: getText('User'),
  EVENT: getText('Event'),
  CANDIDATE: getText('a problem'),
};

export const LISTING_TYPE_HUMAN_TRACKING_NAME_MAP_TITLE = {
  JOB: 'Job',
  INTERNSHIP: 'Internship',
  VOLOP: 'Volunteer Opportunity',
  ORG: 'Organization',
  USER: 'User',
  EVENT: 'Event',
  CANDIDATE: 'a problem',
};
